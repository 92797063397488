<template>
  <v-container fluid>
    <v-tabs v-model="tabs" class="my-tabs" @change="get_data">
      <v-tab> Actuel </v-tab>
      <v-tab> Consommé </v-tab>
    </v-tabs>
    <v-row>
      <v-col cols="12" sm="6" md="12">
        <listitems
          :list="encours_list"
          :title="prd_type == 'p' ? 'Produits ' : 'Intrants'"
          :headers="headers"
          :filename="prd_type == 'p' ? 'Produits ' : 'Intrants'"
          :sheetname="prd_type == 'p' ? 'Produits ' : 'Intrants'"
          :showedit="false"
          :key="klist"
          @open="ProductOpen"
          @rowselect="ProductSelect"
          :add="false"
          :del="false"
          :Total="true"
          :laoding="loading"
          :search_elm="filtre"
        >
        </listitems>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <confirmdialog ref="confirm" :is_motif="is_motif" @motif="get_motif" />
    <formlist
      :title="title"
      :list="form_list"
      :showform="!isListClose"
      :listheaders="headers_detail"
      :key="frml"
      :width="width"
      @close="isListClose = true"
      :Total="true"
      :master="true"
      :laoding="loading"
      @btn_click="consomed"
    >
    </formlist>
  </v-container>
</template>

<script>
import PRODUCTION_LIST_CUMULE from "../graphql/Production/PRODUCTION_LIST_CUMULE.gql";
import PRODUCT_LIST_CUMULE from "../graphql/Production/PRODUCT_LIST_CUMULE.gql";
import PRODUCT_LIST_INPUT from "../graphql/Product/PRODUCT_LIST_INPUT.gql";
import UPDATE_PRODUCT_LIST from "../graphql/Product/UPDATE_PRODUCT_LIST.gql";
import PRODUCTION_LIST from "../graphql/Production/PRODUCTION_LIST.gql";
import UPDATE_FICHE_DETAIL from "../graphql/Production/UPDATE_FICHE_DETAIL.gql";

import ALLATTRIBUTS from "../graphql/Template/ALLATTRIBUTS.gql";

export default {
  components: {
    listitems: () => import("../components/ListItems.vue"),
    formlist: () => import("../components/FormList.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },
  props: {
    prd_type: String,
  },
  data: () => ({
    filtre: "",
    tabs: "0",

    is_motif: false,
    selitem: {},
    title: "",
    loading: false,
    encours_list: [],
    form_list: [],
    colisheaders: [],
    attributes: [],
    width: "1200px",
    colisheaders2: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Code",
        value: "code",
        selected: true,
      },
      {
        text: "Livrée",
        value: "qte_sortie",
        slot: "cur",
        total: "qte_sortie",
        align: "end",
        selected: true,
      },
      {
        text: "Reste",
        value: "qte_rest",
        slot: "cur",
        total: "qte_rest",
        align: "end",
        selected: true,
      },
      {
        text: "Reste (%)",
        value: "prc_rest",
        slot: "progress",
        align: "end",
        selected: true,
      },

      {
        text: "Comment",
        value: "comment",
        selected: true,
      },
      {
        text: "Motif",
        value: "motif",
        hiden: true,
        selected: true,
      },
      {
        text: "",
        slot: "actions",
        selected: true,
        hiden: true,
        icon: "mdi-delete",
        tooltip: "Supprimer (consommé)",
      },
    ],
    headers_detail: [],
    isListClose: true,
    motif: "",
    headers: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Ref",
        value: "article_code",
        selected: true,
      },
      {
        text: "Libelle",
        align: "start",
        value: "product_label",
        selected: true,
      },
      {
        text: "Fournisseur",
        value: "fournis_name",
        selected: true,
        hiden: true,
      },
      {
        text: "Qte",
        value: "qte_rest",
        selected: true,
        slot: "cur",
        align: "end",
      },
      {
        text: "U.M.",
        value: "unit",
        selected: true,
      },
      {
        text: "Nbr Item",
        value: "nbpfd",
        slot: "href22",
        total: "nbpfd",
        selected: true,
        align: "end",
      },
      {
        text: "N° OF",
        value: "no_order",
        selected: true,
      },
      {
        text: "Client",
        value: "client_name",
        hiden: true,
        selected: true,
      },
      {
        text: "N° Cmd",
        value: "nocmd",
        hiden: true,
        selected: true,
      },
      {
        text: "Date Cmd",
        value: "date_cmd",
        hiden: true,
        slot: "date",
        selected: true,
      },
      {
        text: "Machine",
        value: "machine_name",
        hiden: true,
        selected: true,
      },
      {
        text: "Etape",
        value: "gamme_name",
        selected: true,
      },
      {
        text: "Statut",
        value: "statut_name",
        selected: true,
        expire: true,
      },
    ],
    headers_production: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Code",
        value: "code",
        selected: true,
      },
      {
        text: "Qte initiale",
        value: "qte",
        selected: true,
        total: "qte",
        slot: "cur",
        align: "end",
      },
      {
        text: "Reste",
        value: "qte_rest",
        selected: true,
        total: "qte_rest",
        slot: "cur",
        align: "end",
      },
      {
        text: "Reste (%)",
        value: "prc_rest",
        selected: true,
        slot: "progress",
      },
      {
        text: "N° Fiche",
        value: "nofiche",
        selected: true,
      },
      {
        text: "N° BT",
        value: "no_bt",
        selected: true,
      },
      {
        text: "Machine",
        value: "machine_name",
        selected: true,
      },
      {
        text: "Origine",
        value: "origin",
        selected: true,
      },
      {
        text: "Motif",
        value: "motif",
        hiden: true,
        selected: true,
      },
      {
        text: "",
        slot: "actions",
        selected: true,
        hiden: true,
        icon: "mdi-delete",
        tooltip: "Supprimer (Etant consommé)",
      },
    ],
    product: {},
    klist: 0,
    frml: 100,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
  }),

  computed: {
    attributs_list() {
      return this.attributes.filter((elm) => elm.type_attr == "colis");
    },
  },
  watch: {
    prd_type: {
      handler() {
        this.filtre = "";
        this.get_data();
      },
      deep: true,
    },
    "$store.state.filtre": {
      handler() {
        if (this.$store.state.filtre) {
          this.filtre = this.$store.state.filtre;
          this.klist++;
        }
      },
    },
  },
  created() {
    if (this.$route.params.filtre) {
      this.filtre = this.$route.params.filtre;
      this.klist++;
    }
    this.headers[3].hiden = this.prd_type == "p";
    this.headers[8].hiden = this.prd_type == "i";
    this.headers[9].hiden = this.prd_type == "i";
    this.headers[10].hiden = this.prd_type == "i";
    this.headers[11].hiden = this.prd_type == "p";
    this.headers[12].hiden = this.prd_type == "i";
    this.headers[13].hiden = this.prd_type == "i";
  },

  async mounted() {},

  methods: {
    get_motif(m) {
      this.motif = m;
    },
    async consomed(item) {
      this.is_motif =
        this.tabs == 0 &&
        item.prc_rest > this.$store.state.options[0].seuil_justif_stock;
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          (this.tabs == 0
            ? "Considerer cet element comme etant consommé"
            : "Restorer cet element") + "-Etês-vous sûr ?",
          { color: "orange darken-3" }
        )
      ) {
        if (this.prd_type == "p") {
          let v = {
            fichedetail: {
              id: item.id,
              consomed: this.tabs == 0 ? 1 : 0,
              motif: this.tabs == 0 ? this.motif : null,
            },
          };
          let r = await this.maj(UPDATE_FICHE_DETAIL, v);
          if (r) {
            this.form_list.splice(item.index, 1);
            this.frml++;
            this.get_data();
          }
        }
        if (this.prd_type == "i") {
          let v = {
            productlist: {
              id: item.id,
              consomed: this.tabs == 0 ? 1 : 0,
              motif: this.tabs == 0 ? this.motif : null,
            },
          };
          let r = await this.maj(UPDATE_PRODUCT_LIST, v);
          if (r) {
            this.form_list.splice(item.index, 1);
            this.frml++;
            this.get_data();
          }
        }
      }
      this.is_motif = false;
    },
    async maj(query, v, refresh) {
      let r;
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) this.$store.dispatch("Changed", true);
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },
    init_list() {
      this.colisheaders = [];
      this.colisheaders2.forEach((element) => {
        this.colisheaders.push(element);
      });
      this.colisheaders[2].text =
        "Livrée " + (this.product.unit ? "(" + this.product.unit + ")" : "");
      this.colisheaders[2].dec = this.product.decimal
        ? this.product.decimal
        : 2;
      this.colisheaders[3].text =
        "Reste " + (this.product.unit ? "(" + this.product.unit + ")" : "");
      this.colisheaders[3].dec = this.colisheaders[2].dec;
      let colis_attributs = [];

      colis_attributs = this.product.colisattributs
        ? this.product.colisattributs.split(",")
        : [];

      for (let index = 0; index < colis_attributs.length; index++) {
        const element = colis_attributs[index];
        let i = this.attributs_list.findIndex((elm) => elm.id == element);
        if (i >= 0) {
          let ls = [];
          this.attributs_list[i].attributvalues.forEach((element) => {
            ls.push(element.name);
          });
          let elm = {
            text: this.attributs_list[i].caption
              ? this.attributs_list[i].caption
              : this.attributs_list[i].name,
            value: "ref" + index,
            selected: true,
            edit: true,
            readonly: false,
            rules: [
              (v) =>
                !!v ||
                (this.attributs_list[i].caption
                  ? this.attributs_list[i].caption
                  : this.attributs_list[i].name) + " obligatoire",
            ],
            key: this.attributs_list[i].key,
            type:
              this.attributs_list[i].attributvalues.length > 0
                ? "autocomplete"
                : "text",
            select_list: ls,
            show_list: ls,
            sm: 6,
            md: 6,
          };
          this.colisheaders.splice(index + 2, 0, elm);
        }
      }
    },
    async get_data() {
      this.colisheaders2[1].value =
        this.$store.state.options[0].code_list == "0" ? "code" : "ref";
      this.headers_production[1].value =
        this.$store.state.options[0].code_produit == "0" ? "code" : "ref";

      let v = {};
      let r;
      this.headers_production[10].tooltip =
        this.tabs == 0 ? "Supprimer (Etant consommé)" : "Restorer";
      this.headers_production[10].icon =
        this.tabs == 0 ? "mdi-delete" : "mdi-restore";
      this.colisheaders2[7].tooltip =
        this.tabs == 0 ? "Supprimer (Etant consommé)" : "Restorer";
      this.colisheaders2[7].icon =
        this.tabs == 0 ? "mdi-delete" : "mdi-restore";
      this.headers_production[9].hiden = this.tabs == 0;
      this.colisheaders2[6].hiden = this.tabs == 0;
      this.width = this.tabs == 0 ? "1200px" : "1600px";
      if (this.prd_type == "i") {
        v = {
          consomed: this.tabs,
        };
        r = await this.requette(PRODUCT_LIST_CUMULE, v);
        if (r) {
          this.encours_list = r.product_list_cumule;
          this.klist++;
        }
      }
      if (this.prd_type == "p") {
        v = {
          consomed: this.tabs,
        };
        //produit fini
        if (this.$store.state.auth.includes("02024")) {
          v = {
            receipt: 1,
            tosell: 1,
            consomed: this.tabs,
          };
        }
        //matiere premiere
        if (this.$store.state.auth.includes("02044")) {
          v = {
            receipt: 1,
            tobuy: 1,
            tosell: 0,
            consomed: this.tabs,
          };
        }
        r = await this.requette(PRODUCTION_LIST_CUMULE, v);
        if (r) {
          this.encours_list = r.production_list_cumule;
          this.klist++;
        }
      }
      v = {
        TypeScope: 1,
      };
      r = await this.requette(ALLATTRIBUTS, v);
      if (r) {
        this.attributes = r.allattributes;
      }
    },
    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    ProductSelect(item) {
      this.product = item;
      this.selitem = item;
      this.headers_production[10].hiden = !(
        (this.$store.state.auth.includes("02095") ||
          this.$store.state.isadmin) &&
        (this.product.of_statut = 7 || this.product.of_statut == null) &&
        this.product.statut_id == "0"
      );
      this.colisheaders2[7].hiden = !(
        (this.$store.state.auth.includes("02096") ||
          this.$store.state.isadmin) &&
        (this.product.of_statut = 7 || this.product.of_statut == null)
      );
    },
    async ProductOpen(item) {
      this.product = item;
      if (this.prd_type == "p") {
        this.headers_production[2].text =
          "Qte Init." +
          (this.product.unit ? "(" + this.product.unit + ")" : "");
        this.headers_production[3].text =
          "Reste " + (this.product.unit ? "(" + this.product.unit + ")" : "");
        this.headers_detail = this.headers_production;
        this.title =
          "Produit : " +
          item.article_code +
          "-" +
          item.article_label +
          "-" +
          " Etat " +
          item.gamme_name;
        let v = {
          article_id: item.article_id,
          of_id: item.of_id,
          gamme_id: item.gamme_id,
          consomed: this.tabs,
        };

        let r = await this.requette(PRODUCTION_LIST, v);
        if (r) {
          this.form_list = r.production_list;
          this.frml++;
        }
      }

      if (this.prd_type == "i") {
        this.init_list();
        this.headers_detail = this.colisheaders;
        this.title =
          "Produit : " +
          item.article_code +
          "-" +
          item.article_label +
          "-" +
          (item.no_order
            ? " OF N° " + item.no_order
            : " Machine " + item.machine_name);
        let v = {
          article_id: item.article_id,
          of_id: item.of_id,
          machine_id: item.machine_id,
          consomed: this.tabs,
          stock_id: 1,
        };

        let r = await this.requette(PRODUCT_LIST_INPUT, v);
        if (r) {
          this.form_list = r.product_list_input;
          this.frml++;
        }
      }
      this.isListClose = false;
      this.frml++;
    },
  },
};
</script>
